import React, { useEffect } from 'react';
// import Form from '@/components/form';
// import GoogleMap from '@/components/google-map';
import Slider from '@/components/slider';
import Player from '@/components/player';
import { MinistersSection } from '@/components/sections';

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative">
      <Slider />
      <div className="page-section about-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-title-wrapper">
                <h1 className="main-title">
                  <b>Християнська Церква «Благодать»</b>
                </h1>
                <p>
                  {' '}
                  - належить до об’єднання Української Церкви Християн Віри
                  Євангельської (УЦХВЄ).
                </p>
              </div>

              <p>
                Церква була заснована в 2011 році невеликою групою людей, які
                бажали виконувати Божу волю, у місті Білгород-Дністровський,
                Одеська область, Україна.
              </p>

              <p>
                Церква «Благодать» сповідує Євангельське вчення Ісуса Христа,
                вважає своїм обов’язком поширювати Боже Слово — Євангеліє для
                спасіння людей.
              </p>

              <p>Найвищим авторитетом для Церкви є Боже Слово — Біблія.</p>

              <p>
                Церква займає активну духовну та громадську позицію у вирішенні
                соціальних потреб і духовної підтримки всіх людей.
              </p>

              <p>
                Невід’ємною частиною життя кожної людини в церкві є постійне
                практичне життя з молитвою та глибоке вивчення Біблії як єдиного
                джерела Божої істини і волі про кожну людину та світ загалом.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section video-section">
        <div className="container">
          <div className="row">
            <div className="video_wrapper">
              <div className="col-md-10 col-xs-12">
                <Player url="https://youtu.be/pWEHrbC-QkA" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section video-section">
        <div className="container">
          <div className="row">
            <div className="video_wrapper">
              <div className="col-md-10 col-xs-12">
                <Player url="https://youtu.be/qH-cJWt3_iI" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MinistersSection />

      <hr className="mt-0 mb-0" />

      <div id="contact-link" className="page-section p-110-cont grey-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="cis-cont">
                <div className="cis-icon">
                  <div className="icon icon-basic-map" />
                </div>
                <div className="cis-text">
                  <h3>
                    <span className="bold">АДРЕСА</span>
                  </h3>
                  <p>ул. Тімчишина 6, м. Білгород-Дністровський</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="cis-cont">
                <div className="cis-icon">
                  <div className="icon icon-basic-mail" />
                </div>
                <div className="cis-text">
                  <h3>
                    <span className="bold">EMAIL</span>
                  </h3>
                  <p>
                    <a href="mailto:cogakkerman.adm@gmail.com">
                      cogakkerman.adm@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="cis-cont">
                <div className="cis-icon">
                  <div className="icon icon-basic-smartphone" />
                </div>
                <div className="cis-text">
                  <h3>
                    <span className="bold">ТЕЛЕФОН</span>
                  </h3>
                  <div className="cis-text-inner">
                    <div>+380961551815</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
