import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay, Navigation } from 'swiper';
import { createSlides } from '@/data';

const slides = createSlides();

SwiperCore.use([EffectFade, Autoplay, Navigation]);

const Slider = () => {
  const [show, setShow] = useState(false);

  return (
    <div
      style={{ visibility: show ? 'visible' : 'hidden' }}
      className="swiper__wrapper"
    >
      <Swiper
        effect="fade"
        slidesPerView={1}
        // loop
        // navigation
        speed={1500}
        onSwiper={() => setShow(true)}
        autoplay={{
          delay: 5000,
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div
              style={{
                backgroundImage: `url(${slide.image})`,
              }}
              className="slider-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
